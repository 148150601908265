@media only screen and (max-width: 768px) {
  .main-section {
    font-size: 24px;
  }

  .main-button,
  .back-button {
    font-size: 18px;
    padding: 20px 20px;
  }

  .about-section {
    width: 90%; /* Fit better on smaller screens */
    font-size: 18px;
  }
}

@media only screen and (max-width: 480px) {
  .main-section {
    font-size: 20px;
  }

  .main-button,
  .back-button {
    font-size: 16px;
    padding: 15px 15px;
  }

  .about-section {
    width: 100%; /* Maximize space on very small screens */
    font-size: 16px;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.main-section {
  font-size: 3vw; /* More responsive font-size */
  font-family: "Courier New", Courier, monospace;
  color: black;
  display: flex;
  justify-content: center;
  align-items: top;
  min-height: 100vh;
  margin: 0;
  background-color: white;
}

.typewriter {
  margin-top: 30px;
  overflow: hidden;
  border-right: 2px solid white;
  white-space: nowrap;
  margin: 0 auto;
  letter-spacing: 2px;
  animation: typing 2s steps(20, end), blink-caret 1s step-end 2s;
  animation-fill-mode: forwards;
  max-width: 100%; /* Prevents overflow */
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: white;
  }
}

.button-container {
  margin-top: 20px;
}

@media (hover: hover) {
  .main-button:hover {
    font-size: 22px;
    color: red;
  }
}

.ipAddr {
  font-family: "Courier New", Courier, monospace;
  font-size: 20px;
  color: white;
}

.about-section {
  font-family: "Courier New", Courier, monospace;
  font-size: 20px;
  color: white;
  max-width: 500px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0 auto;
}

.main-button,
.back-button {
  font-size: 1.5rem;
  font-family: "Courier New", Courier, monospace;
  padding: 20px 30px;
  background-color: white;
  color: black;
  border: none;
  font-weight: bold;
  transition: color 0.4s ease;
}
